<template>
    <v-card class="my-3">
        <v-card-title
            class="primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
            >Credit</v-card-title
        >

        <v-card-text>
            <credit-score :score="650"></credit-score>
            <credit-factors></credit-factors>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "applicant-credit",
    components: {
        CreditScore: () =>
            import("@components/screen/components/Gadget/CreditScore"),
        CreditFactors: () =>
            import("@components/screen/components/Print/CreditFactors"),
    },
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>