var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-3" },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
        },
        [_vm._v("Credit")]
      ),
      _c(
        "v-card-text",
        [_c("credit-score", { attrs: { score: 650 } }), _c("credit-factors")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }